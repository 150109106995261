import privateConfigSettings from '../../../private-config';

const privateConfigDefaults = {
  useDevApi: false,
  orgId: 'f6acd480-4370-11e8-a8b8-178f6463caf7',
};

const privateConfig = {
  ...privateConfigDefaults,
  ...privateConfigSettings
};

export default {
  orgName: privateConfig.orgName,
  orgId: privateConfig.orgId,

  apiUrl: privateConfig.useDevApi ? 'https://api.waterwatch.io/dev' : 'https://api.waterwatch.io/v1',

  sensorWhitelist: null,

  chart: {
    height: 400
  },

  breakpoints: {
    sensorNav: 800
  },

  privateConfig
}