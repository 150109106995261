import * as React from 'react';
import config from '../config';

export default class extends React.Component {
  render() {
    return <div className="header">
      <img src="/images/waterwatch-o-white.png" alt="Waterwatch logo" className="logo" />
      <div className="brand-name">Waterwatch - <span className="org-name">{config.orgName}</span></div>

      <a href="http://waterwatch.io/" className="waterwatch-link">More about Waterwatch</a>
    </div>
  }
}