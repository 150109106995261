export default {
  waterwatchApiAuth: {
    username: "ZTI0ZDEwYzgtZTNjNS00YzA2LTgzYzctNWIyNmMyYjY4OTIw",
    password:
      "MTUzMjY1MTEyOToxODQ4MDExMTI5OjFkYmE2ODQ0LTQ5NDEtNGRkZC1hYzQzLTNmZTdhOGIyNzVlOQ=="
  },

  useDevApi: false,

  orgId: "911285f0-9133-11e8-9e1f-a770b7a9ad66",
  orgName: "NZ Marine Studies Center"
};
