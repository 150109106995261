export function getMeasurements(sensorSerial: string): Actions.GetMeasurements {
  return {
    type: ActionType.GET_MEASUREMENTS,
    payload: { sensorSerial }
  };
}

export function updateMeasurements(measurements: Measurements, overwrite: boolean = true): Actions.UpdateMeasurements {
  return {
    type: ActionType.UPDATE_MEASUREMENTS,
    payload: { measurements, overwrite }
  };
}