const DEFAULT_STATE: Resource<Sensor[]> = {
  isLoaded: false,
  data: []
}

export default function (state: Resource<Sensor[]> = DEFAULT_STATE, action: any): Resource<Sensor[]> {
  switch (action.type) {
    case ActionType.UPDATE_SENSORS:
      return updateSensors(state, action);

    default:
      return state;
  }
}

function updateSensors(state: Resource<Sensor[]>, action: Actions.UpdateSensors): Resource<Sensor[]> {
  if (action.payload.overwrite) {
    return {
      isLoaded: true,
      data: action.payload.sensors
    };
  }

  return {
    ...state,
    data: {
      ...state.data,
      ...action.payload.sensors
    }
  };
}