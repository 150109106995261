import * as React from 'react';
import { connect } from 'react-redux';
import { ChartContainer, ChartRow, YAxis, Charts, Baseline, LineChart, Resizable } from 'react-timeseries-charts';
import calculateWaterLevel from '../lib/water-level';
import { TimeSeries } from "pondjs";
import config from '../config';
import LoadingSpinner from './loading-spinner';
import { isNullOrUndefined } from 'util';
import calculateChartMinMax from '../lib/calculate-chart-min-max';

const waterLevelAxis = 'waterLevel';

/**
 * Props
 */
interface OwnProps {
  sensor: Sensor;
}

interface StateProps {
  measurements: Measurement[];
}

interface DispatchProps { }
type Props = OwnProps & StateProps & DispatchProps;

/**
 * View Sensor
 */
class ViewSensor extends React.Component<Props> {
  render() {
    const { sensor, measurements } = this.props;

    if (!measurements) {
      return <div className="view-sensor">
        <LoadingSpinner />
      </div>
    }

    if (!measurements.length) {
      return <div>No measurements at the moment</div>;
    }

    const level = calculateWaterLevel(sensor, sensor.latestData.lastMeasurement, 'metres');
    const levelString = `${level}m`;

    const measurementTimeSeries = new TimeSeries({
      name: "Water Level",
      columns: ["time", "waterLevel"],
      points: measurements.map(measurement => [measurement.time, calculateWaterLevel(sensor, measurement) / 1000])
    });

    const displayTimeRange = measurementTimeSeries && measurementTimeSeries.timerange();

    // Calculate chart min and max
    const measurementsMin = measurementTimeSeries.min("waterLevel");
    const measurementsMax = measurementTimeSeries.max("waterLevel");
    const chartMinMax = calculateChartMinMax(sensor, measurementsMin, measurementsMax);

    // Calculate alarm level and label - if there is one
    let alarmLevel = null;
    let alarmLabel = null;

    if (sensor.config && sensor.config.alarm) {
      const { threshold, type } = sensor.config.alarm;

      // If there is an offset, the alarm level is 'offset - threshold'
      if (sensor.displayInfo && !isNullOrUndefined(sensor.displayInfo.offsetMeasurement)) {
        const offset = sensor.displayInfo.offsetMeasurement;
        alarmLevel = (offset - threshold) / 1000;
      }
      else {
        alarmLevel = threshold / 1000;
      }

      const hasOffset = !!(sensor.displayInfo && sensor.displayInfo.offsetMeasurement);
      alarmLabel = getThresholdLabel(type, hasOffset);
    }

    return <div className="view-sensor">
      <div className="view-sensor-title-container">
        <div className="view-sensor-title">
          <h2 className="view-sensor-name">{sensor.name}</h2>
          <div className="view-sensor-level">{levelString}</div>
        </div>

        <div className="view-sensor-serial">Serial: {sensor.serial}</div>
      </div>

      {!sensor.displayInfo.offsetMeasurement && <div className="alert">&#9432;&nbsp;&nbsp;We don't have a baseline for this sensor. The measurement displayed is the distance from the sensor to the water.</div>}

      <Resizable className="chart">
        <ChartContainer timeRange={displayTimeRange}>
          <ChartRow height={config.chart.height}>
            <YAxis id={waterLevelAxis} format={`,.2f`} min={chartMinMax.min} max={chartMinMax.max} width={30} type="linear" />
            <Charts>
              {/* Water level line */}
              <LineChart axis={waterLevelAxis} series={measurementTimeSeries} breakLine={false} columns={["waterLevel"]} />

              {/* Alarm */}
              {alarmLevel
                ? <Baseline axis={waterLevelAxis} value={alarmLevel} label={alarmLabel} position="right" />
                : <div /> // using div is a workaround to prevent the lib to invoke props on null child
              }
            </Charts>
          </ChartRow>
        </ChartContainer>
      </Resizable>
    </div>
  }
}

export default connect<StateProps, DispatchProps, OwnProps, AppState>(
  // Map state to props
  (state, ownProps) => ({
    measurements: state.measurements[ownProps.sensor.serial]
  })
)(ViewSensor);

/**
 * Utility functions
 */

function getThresholdLabel(type: ThresholdType, hasOffset: boolean): string {
  switch (type) {
    case ThresholdType.DECREASING_DISTANCE:
      return hasOffset ? "Rising Alarm" : "Distance Decreasing Alarm";

    case ThresholdType.INCREASING_DISTANCE:
      return hasOffset ? "Falling Alarm" : "Distance Increasing Alarm";

    default:
      return "";
  }
}
