const DEFAULT_STATE = {};

export default function (state: Measurements = DEFAULT_STATE, action: any): Measurements {
  switch (action.type) {
    case ActionType.UPDATE_MEASUREMENTS:
      return updateMeasurements(state, action);

    default:
      return state;
  }
}

function updateMeasurements(state: Measurements, action: Actions.UpdateMeasurements): Measurements {
  if (action.payload.overwrite) {
    return action.payload.measurements;
  }

  return {
    ...state,
    ...action.payload.measurements
  };
}