export function getSensors(): Actions.GetSensors {
  return {
    type: ActionType.GET_SENSORS,
    payload: null
  };
}

export function updateSensors(sensors: Sensor[], overwrite: boolean = true): Actions.UpdateSensors {
  return {
    type: ActionType.UPDATE_SENSORS,
    payload: { sensors, overwrite }
  };
}