export default function calculateWaterLevel(sensor: Sensor, measurement: Measurement, unit: 'millimetres' | 'metres' = 'millimetres'): number {
  const offset = sensor.displayInfo.offsetMeasurement;
  const rawDistance = measurement.rawDistance;

  const millimetres = offset ? offset - rawDistance : rawDistance;

  if (unit === 'millimetres') {
    return millimetres;
  }

  // Return metres
  return millimetres / 1000;
}