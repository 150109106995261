"use strict";
import 'babel-polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { configureStore } from "./configure-store";
import { BrowserRouter as Router } from 'react-router-dom';

import App from './components/app';

// Import Styles
import '../styles/styles.scss';

const store = configureStore();

// Render the app
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('main')
);