import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link, Redirect } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import { getMeasurements } from '../actions/measurements';
import { getSensors } from '../actions/sensors';
import config from '../config'

import SensorNav from './sensor-nav';
import ViewSensor from './view-sensor';
import LoadingSpinner from './loading-spinner';

/**
 * Props
 */
interface OwnProps { }
interface StateProps {
  sensors: Resource<Sensor[]>;
  measurements: Measurements;
}

interface DispatchProps {
  getMeasurements: typeof getMeasurements;
  getSensors: typeof getSensors;
}

type Props = OwnProps & StateProps & DispatchProps;

/**
 * Body
 */
class Body extends React.Component<Props> {
  componentDidMount() {
    this.props.getSensors();
  }

  componentDidUpdate() {
    const { measurements, sensors } = this.props;

    if (sensors.isLoaded && Object.keys(measurements).length == 0) {
      sensors.data.map(sensor => this.props.getMeasurements(sensor.serial));
    }
  }

  render() {
    const { sensors } = this.props;

    if (!sensors.isLoaded) {
      return <LoadingSpinner />
    }

    return <div className="app-container">
      {/*
        * Mobile routing
        */}
      <MediaQuery maxWidth={config.breakpoints.sensorNav - 1}>
        <Switch>
          <Route exact path="/">
            <SensorNav />
          </Route>

          <Route exact path={`/sensor/:serial`}
            render={({ match }) => {
              const sensor = findSensor(sensors.data, match.params.serial);

              return <div>
                <button className="all-sensors-button">
                  <Link to="/">
                    <div className="menu-bars">
                      <div /><div /><div />
                    </div>

                    All Sensors
                  </Link>
                </button>
                <ViewSensor sensor={sensor} />
              </div>
            }}
          />
        </Switch>
      </MediaQuery>

      {/*
        * Desktop style routing
        */}
      <MediaQuery minWidth={config.breakpoints.sensorNav}>
        <SensorNav />

        <div className="content">
          <Switch>
            <Route exact path="/" render={() => <Redirect to={`/sensor/${sensors.data[0].serial}`} />} />

            <Route exact path={`/sensor/:serial`}
              render={({ match }) => {
                const sensor = findSensor(sensors.data, match.params.serial);

                if (!sensor) {
                  return <Redirect to="/" />;
                }

                return <ViewSensor sensor={sensor} />
              }}
            />
          </Switch>
        </div>
      </MediaQuery>
    </div>
  }
}

/**
 * Connect Body to redux state
 */
const ConnectedBody = connect<StateProps, DispatchProps, OwnProps, AppState>(
  // Map state to props
  state => ({
    sensors: state.sensors,
    measurements: state.measurements,
  }),

  // Map dispatch to props
  { getMeasurements, getSensors, }
)(Body);

export default withRouter(ConnectedBody);


/**
 * Utils
 */

function findSensor(sensors: Sensor[], serial: string): Sensor {
  return sensors.find(sensor => sensor.serial === serial) as Sensor;
}