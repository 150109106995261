import * as React from 'react';

import Header from './header';
import Body from './body';

export default class App extends React.Component {
  render() {
    return <div>
      <Header />
      <Body />
    </div>
  }
}