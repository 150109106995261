import { createStore, applyMiddleware, compose } from 'redux';
import { rootReducer } from './reducers';
import createSagaMiddleware from 'redux-saga';
import runSagas from "./sagas";
import { createLogger } from "redux-logger";

export function configureStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const loggerMiddleware = createLogger();
  const isDevBuild = process.env.NODE_ENV !== "production";

  const composeEnhancers =
    isDevBuild && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const middlewares = [
    sagaMiddleware
  ];

  if (isDevBuild) {
    middlewares.push(loggerMiddleware);
  }

  const middleware = applyMiddleware(...middlewares);

  const createStoreWithMiddleware = composeEnhancers(middleware)(createStore);
  const store = createStoreWithMiddleware(rootReducer, initialState);

  // Run sagas
  runSagas(sagaMiddleware);

  return store;
}
