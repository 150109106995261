import { SagaMiddleware } from 'redux-saga';
import { put, all, takeEvery } from 'redux-saga/effects';
import callApi from '../lib/call-api';
import { updateMeasurements } from '../actions/measurements';
import { updateSensors } from '../actions/sensors';
import config from '../config';

export default function runSagas(sagaMiddleware: SagaMiddleware<{}>) {
  sagaMiddleware.run(sagas);
}

function* sagas() {
  yield all([
    getMeasurementsSaga(),
    getSensorsSaga(),
  ]);
}

function* getMeasurementsSaga() {
  yield takeEvery(ActionType.GET_MEASUREMENTS, getMeasurements);

  function* getMeasurements(action: Actions.GetMeasurements) {
    const { sensorSerial } = action.payload;

    // Request meassurements for all sensors
    let startDate = new Date()
    startDate.setDate(startDate.getDate() - 3);
    const startTime = startDate.getTime();

    const resource = `organisations/${config.orgId}/sensors/${sensorSerial}/measurements?order=asc&startTime=${startTime}`;
    const response = yield callApi(resource);

    // Put measurements in state
    const updateAction = updateMeasurements({
      [sensorSerial]: response.data.measurements
    }, false);

    yield put(updateAction);
  }
}

function* getSensorsSaga() {
  yield takeEvery(ActionType.GET_SENSORS, getSensors);

  function* getSensors(action: Actions.GetSensors) {
    const resource = `organisations/${config.orgId}/sensors`;
    const response = yield callApi(resource);

    // Filter out sensors without a last measurement
    let sensors = response.data.filter(sensor => sensor.latestData.lastMeasurement);

    // Apply whitelist if one has been provided - defined in config
    if (config.sensorWhitelist !== null) {
      sensors = sensors.filter(sensor => config.sensorWhitelist.includes(sensor.serial));
    }

    const updateAction = updateSensors(sensors);
    yield put(updateAction);
  }
}