export default function calculateChartMinMax(sensor: Sensor, measurementsMin: number, measurementsMax: number) {
  let min = measurementsMin * 1.1;
  let max = measurementsMax * 1.1;

  if (!sensor.displayInfo) {
    return { min, max };
  }

  const displayMin = sensor.displayInfo.minLevel / 1000;
  const displayMax = sensor.displayInfo.maxLevel / 1000;

  min = Math.min(displayMin, min);
  max = Math.max(displayMax, max);

  return { min, max };
}