import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import waterLevel from '../lib/water-level';

interface PropsFromState {
  sensors: Resource<Sensor[]>;
}

type Props = PropsFromState;

class SensorNav extends React.Component<Props> {
  render() {
    const { sensors } = this.props;

    return <div className="sensor-nav">
      {sensors.data.map(sensor => <NavLink to={`/sensor/${sensor.serial}`} key={sensor.serial}>
        <div className="sensor-nav-details">
          <div className="sensor-nav-name">{sensor.name}</div>
          <div>{sensor.serial}</div>
        </div>

        <div className="sensor-nav-level">{waterLevel(sensor, sensor.latestData.lastMeasurement, 'metres')}m</div>
      </NavLink>
      )}
    </div>
  }
}

export default connect(
  // Map state to props
  (state: AppState) => ({
    sensors: state.sensors
  }),

  // work around, so we don't need to use withRouter() - which throws more TS errors than I want to fix
  null, null, { pure: false }
)(SensorNav);
